<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">敏感词管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl searchboxfl">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="敏感词" class="searchboxItem ci-full">
              <span class="itemLabel">敏感词:</span>
              <el-input
                v-model="word"
                type="text"
                size="small"
                placeholder="请输入敏感词"
                clearable
              />
            </div>
            <div title="敏感词分类" class="searchboxItem ci-full">
              <span class="itemLabel" style="width:100px">敏感词分类:</span>
              <el-select
                v-model="category"
                placeholder="请选择分类"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in categorylist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="add()"
            >新增</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="getImport()"
              >批量导入</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="syncInfo()"
            >同步</el-button>
          </div>
        </div>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="敏感词"
                align="left"
                show-overflow-tooltip
                prop="word"
                fixed
                min-width="200px"
              />
              <el-table-column
                label="敏感词分类"
                align="left"
                show-overflow-tooltip
                prop="category"
              >
                <template slot-scope="scope">
                  <span>
                    {{ $setDictionary("SENSITIVE_WORDS_CATEGORY", scope.row.category) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="200px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" size="mini" @click="edit(scope.row.id)">编辑</el-button>
                  <el-button type="text" size="mini" @click="remove(scope.row.id)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
        <el-dialog
          :title="'新增敏感词'"
          :visible.sync="DialogVisible"
          width="45%"
          center
          top="5%"
          @close="doClose"
        >
          <div style="height:260px" class="ovy-a">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="8rem"
              class="demo-ruleForm"
              style="width: 90%"
            >
              <el-form-item label="敏感词：" prop="word" required >
                <el-input
                    v-model="ruleForm.word"
                    size="small"
                    placeholder="请输入敏感词"
                    show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="敏感词分类：" prop="category" required >
                <el-select
                    v-model="ruleForm.category"
                    placeholder="请选择分类"
                    size="small"
                    clearable
                >
                  <el-option
                      v-for="item in categorylist"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="display: flex; justify-content: center">
              <el-button @click="doClose">取消</el-button>
              <el-button class="bgc-bv" @click="submitForm">确定</el-button>
            </div>
          </div>
        </el-dialog>
        <el-dialog
            :title="'敏感词导入'"
            :visible.sync="importVisible"
            width="50%"
            center
            :before-close="closeImport"
            class="exportdialog"
        >
          <div class="flexdcc">
            <div class="export-box">
              <h1>
                1
                <span>下载并填写导入模板</span>
              </h1>
              <div class="df">
                <span @click="downloadExcel"
                      style="margin-top: 20px;
                             margin-right: 20px;
                             color: #409eff;
                             cursor: pointer;"
                            >敏感词导入模板</span
                            >
              </div>
            </div>
            <div class="export-box">
              <h1>
                2
                <span>导入编写好的Excel文档</span>
              </h1>
              <div class="flexdc">
                <div class="df" style="margin: 20px 0 10px">
                  <el-upload
                      class="upload-demo upload-workers"
                      :on-change="uploadChange"
                      :show-file-list="false"
                      :auto-upload="false"
                  >
                    <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
                    >浏览</el-button
                    >
                  </el-upload>
                  <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                    当前选择文件：
                    <span style="color: #f46173">{{ fileName }}</span>
                  </p>
                  <p v-else style="margin-top: 10px; margin-left: 10px">
                    未选择文件
                  </p>
                </div>
                <div>
                  <el-button
                      class="bgc-bv"
                      size="mini"
                      style="margin-top: 10px; height: 35px"
                      @click="doExport"
                  >开始导入</el-button
                  >
                </div>
              </div>
            </div>
            <div class="export-box">
              <h1>
                3
                <span>导入结果</span>
              </h1>
              <div class="df studentNum">
                <span>记录总数:{{ total }}条;</span>
                <span>成功:{{ successNum }}条;</span>
                <span>失败:{{ failedNum }}条;</span>
              </div>
              <div>
                <el-button
                    class="bgc-bv"
                    style="margin-top: 15px; height: 35px"
                    size="mini"
                    :disabled="!haveError"
                    @click="doExportError"
                >导出错误数据</el-button
                >
              </div>
              <div style="margin-top: 15px; color: #dd1d35" v-if="haveError">
                有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "sensitive_word",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      word: "",
      category: "",
      params: {},
      categorylist: [],
      DialogVisible: false,
      ruleForm: {
        id: "",
        word: "",
        category: "",
      },
      rules: {
        word: [{ required: true, message: "请输入敏感词", trigger: "blur" }],
        category: [{ required: true, message: "请选择敏感词分类", trigger: "change" }],
      },
      importVisible: false,
      fileName: "",
      fileKey: "",
      batchId: "",
      haveError: false,
      total: "0",
      successNum: "0",
      failedNum: "0",
    };
  },
  created() {
  },
 computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getCategoryList();
    this.getTableHeight();
  },
  methods: {
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.word) {
        params.word = this.word;
      }
      if (this.category) {
        params.category = this.category;
      }
      this.doFetch({
        url: "/biz/sensitive/word/pageList",
        params,
        pageNum,
      });
    },
    add() {
      this.DialogVisible = true;
    },
    edit(id) {
      this.$post("/biz/sensitive/word/getInfo", {id: id})
          .then((ret) => {
            this.ruleForm.id = ret.data.id;
            this.ruleForm.word = ret.data.word;
            this.ruleForm.category = ret.data.category;
          })
          .catch(() => {
            return;
          });
      this.DialogVisible = true;
    },
    doClose() {
      this.$refs.ruleForm.resetFields();
      this.DialogVisible = false;
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let api = "/biz/sensitive/word/insert";
          let params = {
            word : this.ruleForm.word,
            category : this.ruleForm.category,
          }
          if (this.ruleForm.id) {
            params.id = this.ruleForm.id;
            api = "/biz/sensitive/word/modify";
          }
          this.$post(api, params).then((ret) => {
            if (ret.status == "0") {
              this.getData();
              this.doClose();
            }
          })
        } else {
          return false;
        }
      });
    },
    getImport() {
      this.importVisible = true;
    },
    closeImport() {
      this.fileName = "";
      this.fileKey = "";
      this.batchId = "";
      this.total = "0";
      this.successNum = "0";
      this.failedNum = "0";
      this.haveError = false;
      this.importVisible = false;
    },
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.fileKey = result.data.fileKey;
          })
          .catch((err) => {
            return;
          });
    },
    downloadExcel() {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/sensitive_word.xls";
      link.setAttribute("download", "sensitive_word.xls");
      document.body.appendChild(link);
      link.click();
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        const parmar = {
          excelUrl: this.fileKey
        };
        this.$post("/biz/sensitive/word/import", parmar, 5000)
            .then((res) => {
              if (res.status == 0) {
                this.haveError = res.data.haveError;
                this.batchId = res.data.batchId;
                this.total = res.data.total;
                this.successNum = res.data.successNum;
                this.failedNum = res.data.failedNum;
                this.$message({
                  type: "success",
                  message: "导入成功",
                });
              }
            })
            .catch(() => {
              return;
            });
      }
    },
    //导出错误数据
    doExportError() {
       console.log(this.batchId, this.haveError);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
            "/biz/sensitive/word/export/error",
            { batchId: this.batchId },
            5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    syncInfo() {
      this.$post("/biz/sensitive/word/sync");
    },
    remove(id) {
      this.$confirm("此操作将永久删除该敏感词, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
          .then(() => {
            this.$post("/biz/sensitive/word/remove", { id: id }).then(
                (res) => {
                  this.getData();
                });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    getCategoryList() {
      const dictlist = this.$setDictionary("SENSITIVE_WORDS_CATEGORY", "list");
      const list = [];
      for (const key in dictlist) {
        list.push({
          value: key,
          label: dictlist[key]
        });
      }
      this.categorylist = list;
   },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
</style>
